import React, { useState, useEffect } from "react";

// components
import { Button } from "../../../components/Button";
import HeaderContent from "../../../layouts/HeaderContent";
import { Input, InputCurrency, MessageError } from "../../../components/Input";
import UploadImage from "../../../components/UploadImage";
import CheckCard from "../../../components/CheckCard";

// icons
import { FiSave, FiXCircle } from "react-icons/fi";

// libraries
import axios from "../../../utils/axios";
import { swNormal } from "../../../utils/sw";
import { getBase64 } from "../../../utils/image";
import { baseUrl, rupiahToNumber, rupiah } from "../../../utils/strings";
import { useMutation, QueryClient, useQuery } from "react-query";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import AsyncCreatableSelect from "react-select/async-creatable";
import { toastSuccess, toastError } from "../../../utils/toast";
import { useDropzone } from "react-dropzone";

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    height: "3rem",
    paddingLeft: ".5rem",
    paddingRight: ".5rem",
    borderRadius: "10px",
    borderWidth: "1px",
    borderColor: "rgb(59 130 246 / 1)",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      // backgroundColor: isDisabled ? 'red' : 'blue',
      // color: '#FFF',
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
};

const EditBahan = () => {
  const location = useLocation();
  const dataProduk = location.state;
  const user = JSON.parse(localStorage.getItem("user"));

  const [errMessage, setErrMessage] = useState("");
  const [imageBase64, setImageBase64] = useState("");
  const [isAction, setIsAction] = useState(false);
  const [statusDiskon, setStatusDiskon] = useState(dataProduk.status_diskon);
  const [isLoadingBahan, setIsLoadingBahan] = useState(true);
  const queryClient = new QueryClient();
  const navigate = useNavigate();
  const breadcrumbs = [
    { link: "/", menu: "Home" },
    { link: "/produk", menu: "Produk" },
    { link: "/produk/edit", menu: "Edit Stok Produk" },
  ];
  const [bahanValues, setBahanValues] = useState([]);


  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    control,
    setValues,
    setFocus,
  } = useForm();

  useEffect(() => {
    fetchBahanProduk()

    if (dataProduk.gambar) {
      localStorage.setItem("image", JSON.stringify(baseUrl + dataProduk.gambar));
    }
  }, []);

  useEffect(() => {
    console.log(bahanValues.some(bahan => bahan.id == 5))
    console.log(bahanValues)
  }, [bahanValues]);

  const fetchBahanProduk = async () => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
    const response = await axios.get(
      `produk/` + dataProduk.id + `/bahan`
    );
    const res = await response.data;
    const data = res.data;

    setBahanValues([...data])
    setIsLoadingBahan(false)
  };

  // dropzone
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      acceptedFiles.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  useEffect(() => {
    setFocus("nama_produk");
  }, [setFocus]);

  const submitData = async (data) => {
    data.bahan = bahanValues.map((item) => {
      return {
        'id_bahan': item.id,
        'qty': item.qty,
      }
    })

    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const response = await axios.put("produk/ubah-bahan/" + dataProduk.id, data, config);
    const res = response.data;

    if (res.meta.code != 200) {
      throw new Error(res.meta.message);
    }

    return res.data;
  };

  const mutation = useMutation(submitData, {
    onMutate: () => {
      // spinner
      setIsAction(!isAction);
    },
    onSettled: async (data, error) => {
      setIsAction(!isAction);

      if (data) {
        queryClient.invalidateQueries("data-produk");
        reset();
        setErrMessage("");
        clearErrors();
      }

      if (error) {
        setErrMessage(error.message);
      }
    },
    onSuccess: async () => {
      navigate("/produk");
      toastSuccess("Produk Berhasil Diedit");
    },
    onError: async () => {
      navigate("/produk");
      toastError("Produk Gagal Diedit");
    },
  });

  const editDataStok = async (data) => {
    await mutation.mutate(data);
  };

  const showHargaDiskon = (e) => {
    let inputHargaDiskon = document.getElementById("content-harga-diskon");

    if (e.target.checked) {
      setStatusDiskon(1);
      inputHargaDiskon.classList.add("block");
      inputHargaDiskon.classList.remove("hidden");
    } else {
      setStatusDiskon(0);
      inputHargaDiskon.classList.add("hidden");
      inputHargaDiskon.classList.remove("block");
    }
  };

  const gambar_lama = JSON.parse(localStorage.getItem("image"));

  const files = acceptedFiles.map((file, key) => {
    getBase64(file)
      .then((result) => {
        setImageBase64(result);
        localStorage.removeItem("image");
      })
      .catch((err) => {
        console.log(err);
      });

    return (
      <img
        key={key}
        src={URL.createObjectURL(file)}
        alt={file}
        // Revoke data uri after image is loaded
        onLoad={() => {
          URL.revokeObjectURL(file);
        }}
      />
    );
  });

  const {
    data: dataBahan,
    isFetching: isFetchingBahan,
  } = useQuery(["data-bahan"], () => fetchDataBahan(), {
    keepPreviousData: true,
  });

  const fetchDataBahan = async () => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
    const response = await axios.get(
      `bahan`
    );
    const res = await response.data.data.data;
    const data = res.data;

    return data;
  };

  const handleBahanQtyChange = (id, value) => {
    setBahanValues(prevBahanValues =>
      prevBahanValues.map(bahan =>
          bahan.id === id ? { ...bahan, qty: value } : bahan
      )
  );
  }
    
  const handleBahanChange = (id) => {
    setBahanValues(prevBahanValues => {
      const index = prevBahanValues.findIndex(bahan => bahan.id === id);
      if (index !== -1) {
          return prevBahanValues.filter(bahan => bahan.id !== id);
      } else {
          return [...prevBahanValues, { id, qty: 0 }];
      }
  });
  };

  return (
    <>
      <HeaderContent
        linkBack="/produk"
        title="Edit Stok Produk"
        breadcrumbs={breadcrumbs}
      ></HeaderContent>
      <div className="bg-white h-max px-6 rounded-lg mt-0 md:mt-4">
        <form onSubmit={handleSubmit(editDataStok)} className="grid grid-cols-3 gap-3">
          <div className="col-span-1">
            <input type="hidden" name="id" {...register("id")} />
            <div className="relative h-fit bg-white shadow-md rounded-2xl p-2 mx-1">
              <div className="rounded-2xl">
                <img className="h-40 rounded-2xl w-full" src={baseUrl + dataProduk.gambar} alt={dataProduk.gambar} />
              </div>
              <div className="mt-4 pl-2 mb-2 flex justify-between">
                <div>
                  <p className="text-lg font-semibold text-gray-900 mb-0">{dataProduk.nama_produk}</p>
                  <p className="text-md text-gray-800 mt-0">SKU : {dataProduk.nomor_sku}</p>
                  <p className="text-md text-gray-800 mt-0">Kategori : {dataProduk.kategori_produk.kategori_produk}</p>
                  <p className="text-md text-gray-800 mt-0">Harga Jual : Rp. {rupiah(dataProduk.harga_jual)}</p>
                </div>
              </div>
            </div>
            <div className="col-span-3 flex justify-end items-center mt-4">
              <Button
                className="text-xs mr-2 bg-custom-blue border-custom-blue"
                type="submit"
                startIcon={<FiSave size={20} />}
                loading={isAction}
                title="Simpan"
              />
              <Button
                className="text-xs"
                color="ghost"
                type="button"
                startIcon={<FiXCircle size={20} />}
                loading={false}
                title="Kembali"
                onClick={() => navigate("/produk")}
              />
            </div>
          </div>
          <div className="col-span-2 gap-3">
            {isLoadingBahan ? (
              <div>Loading...</div>
            ) : (
              dataBahan?.map((obj, key) => (
                <div className="mb-2 flex items-center gap-2" key={key}>
                  <div className="w-3/4">
                    <CheckCard title={obj.nama_bahan} selected={bahanValues.some(item => item.id == obj.id)} onChange={() => handleBahanChange(obj.id)} />
                  </div>
                  <div className="w-1/4">
                    <Input
                      type="number"
                      placeholder="Kuantitas"
                      value={bahanValues.find(item => item.id === obj.id)?.qty ?? 0}
                      onChange={(e) => handleBahanQtyChange(obj.id, e.target.value)}
                    />
                  </div>
                </div>
              ))
            )}
          </div>
        </form>
      </div>

    </>
  );
};

export default EditBahan;
