import React, { useRef, useState, useEffect } from "react";
import Logo from "../../../assets/images/logo/SiResto.png";

// icons
import { FiPrinter, FiDownload } from "react-icons/fi";

// components
import { Button } from "../../../components/Button";

// libraries
import moment from "moment";
import { useLocation } from "react-router-dom";
import {
    rupiah
} from "../../../utils/strings";
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
import ReactToPrint, { useReactToPrint } from "react-to-print";
import axios from "../../../utils/axios";
import "./Cetak/style.css"

export default function Struk() {
    const location = useLocation();
    const data = location.state;
    const componentRef = useRef();
    const user = JSON.parse(localStorage.getItem("user"));
    const [resto, setResto] = useState({});
    const [firstPrint, setFirstPrint] = useState(true);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: '@media print { .divider { margin: 0 !important; padding: 0 !important; overflow: hidden; } }'
    });

    const handlePrintTwice = () => {
        if (data.print2x) {
            handlePrint();
            setFirstPrint(false);
            setTimeout(() => {
                handlePrint();
                setTimeout(() => {
                    setFirstPrint(true);
                }, 3000);
            }, 3000);
        } else {
            handlePrint();
        }
    };

    const totalQty = data.produk.reduce((total, produk) => {
        return total + produk.jumlah_beli;
    }, 0);

    const totalTransaksi = data.produk.reduce((total, produk) => {
        return total + (produk.jumlah_beli * produk.harga_jual);
    }, 0);

    const fetchResto = async () => {
        axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
        const response = await axios.get("resto/show");
        const res =  response.data.data;

        setResto(res);
    };

    useEffect(() => {
        fetchResto();
    }, []);

    return (
        <div className="flex flex-col items-center gap-4 justify-center">
            <div ref={componentRef} id="struk-order" style={{ width: "56mm", maxWidth: "56mm", fontSize: "12px", fontFamily: "Arial, sans-serif" }}>
                {!firstPrint &&
                    <p style={{ fontSize: "10px", fontFamily: "Arial, sans-serif" }}>Salinan Pelanggan</p>
                }
                <div style={{ textAlign: "center" , fontSize: "12px", fontFamily: "Arial, sans-serif"  }}>
                    <img
                        src={resto?.logo ? process.env.REACT_APP_API_DOMAIN + resto.logo : Logo}
                        alt={`Logo ${data?.resto?.nama_resto || 'Siresto Logo'}`}
                        style={{ width: "40mm", margin: "0 auto 2mm" }}
                    />
                </div>

                {/* <div style={{ textAlign: "center", fontSize: "12px", fontFamily: "Arial, sans-serif" }}>
                    {resto?.logo? (
                        <img
                            src={process.env.REACT_APP_API_DOMAIN + resto?.logo}
                            alt={`Logo ${data?.resto?.nama_resto}`}
                            style={{ width: "40mm", margin: "0 auto 2mm" }}
                        />
                    ) : (
                        <img
                            src={Logo}
                            alt="Siresto Logo"
                            style={{ width: "40mm", margin: "0 auto 2mm" }}
                        />
                    )}
                </div> */}
                <div className="divider" style={{ fontSize: "12px", fontFamily: "Arial, sans-serif" }}>--------------------------------</div>
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "16px", fontSize: "12px", fontFamily: "Arial, sans-serif" }}>
                    <div style={{ textAlign: "left", fontSize: "12px", fontFamily: "Arial, sans-serif" }}>
                        <p>{data.tanggal}</p>
                        <p>{data.waktu}</p>
                        <p>{data.noTransaksi}</p>
                    </div>
                    <div style={{ textAlign: "left", fontSize: "12px", fontFamily: "Arial, sans-serif" }}>
                        <p>{data.namaPelanggan}</p>
                    </div>
                </div>
                <div className="divider" style={{ fontSize: "12px", fontFamily: "Arial, sans-serif" }}>--------------------------------</div>
                <div style={{ textAlign: "left", fontSize: "12px", fontFamily: "Arial, sans-serif" }}>
                    {data?.produk?.map((obj, key) => (
                        <div key={key} style={{ marginBottom: "1mm" }}>
                            <p>{key + 1}. {obj.produk.nama_produk}</p>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <p>{obj.jumlah_beli} x Rp. {rupiah(obj.harga_jual)}</p>
                                <p>Rp. {rupiah(obj.jumlah_beli * obj.harga_jual)}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="divider" style={{ fontSize: "12px", fontFamily: "Arial, sans-serif" }}>--------------------------------</div>
                <div style={{ fontSize: "12px", fontFamily: "Arial, sans-serif" }}>
                    {data.namaKasir &&
                        <div style={{ textAlign: "left", marginBottom: "2mm", fontSize: "12px", fontFamily: "Arial, sans-serif" }}>
                            <p>Kasir: {data?.namaKasir}</p>
                        </div>
                    }
                    <div style={{ textAlign: "left", marginBottom: "2mm", fontSize: "12px", fontFamily: "Arial, sans-serif" }}>
                        <p>Total QTY: {totalQty}</p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", fontSize: "12px", fontFamily: "Arial, sans-serif" }}>
                        <p className="font-bold">Total:</p>
                        <p>Rp {rupiah(totalTransaksi)}</p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", fontSize: "12px", fontFamily: "Arial, sans-serif" }}>
                        <p>Bayar:</p>
                        <p>Rp {rupiah(data?.bayar)}</p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", fontSize: "12px", fontFamily: "Arial, sans-serif" }}>
                        <p>Kembali:</p>
                        <p>Rp {rupiah(data?.kembali)}</p>
                    </div>
                </div>
                <div className="divider" style={{ fontSize: "12px", fontFamily: "Arial, sans-serif" }}>--------------------------------</div>
                <div style={{ textAlign: "center", fontSize: "12px", fontFamily: "Arial, sans-serif" }}>
                    <p>Terimakasih Telah Berbelanja</p>
                </div>
            </div>
            <Button
                className="text-xs px-16 w-full sm:w-1/4"
                type="button"
                startIcon={<FiDownload size={16} />}
                loading={false}
                title="Download"
                onClick={() => {
                    exportComponentAsJPEG(componentRef)
                }}
            />
            <Button
                className="text-xs px-16 w-full sm:w-1/4 mb-5"
                type="button"
                startIcon={<FiPrinter size={16} />}
                loading={false}
                title="Cetak"
                onClick={() => {
                    handlePrintTwice()
                }}
            />
        </div>
    );
}
