import React, { useState } from "react";
import { FiCheckCircle } from "react-icons/fi";

const CheckCard = ({ title, onChange, selected = false }) => {
  const [clicked, setClicked] = useState(selected);

  const handleClick = () => {
    setClicked(!clicked)
    onChange()
  };

  return (
    <div
      style={{ userSelect: 'none' }}
      className={`h-fit cursor-pointer text-center shadow-inner border rounded-xl px-4 py-2 ${clicked ? 'bg-blue-500 text-white' : 'bg-white'}`} onClick={handleClick}>
        <div className="flex justify-center items-center">
        {title}
        {clicked ? <FiCheckCircle className="ml-2" /> : null}
      </div>
    </div>
  );
};

export default CheckCard;
